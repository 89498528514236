var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-modal",
        {
          ref: "master-invoice-modal",
          staticClass: "modal-dialog",
          attrs: {
            id: "master-invoice-modal",
            "hide-header": "",
            "ok-only": ""
          }
        },
        [
          _c("b-card", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("Master-Invoice", {
                    attrs: { propafes: this.afes, propinvoice: this.invoice }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "clearfix" },
        [
          _c("InvoiceHeader", {
            attrs: {
              invoice: _vm.invoice,
              vwref: _vm.vwref,
              serviceData: _vm.serviceData,
              afes: _vm.afes,
              currency: _vm.currency,
              tax: _vm.tax,
              company_name: _vm.company_name
            }
          }),
          _c(
            "div",
            { staticClass: "mt-2 invoice-status" },
            [_c("InvoiceStatus", { attrs: { invoice: _vm.invoice } })],
            1
          ),
          _c(
            "div",
            [
              !_vm.fromexternal
                ? _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.master-invoice-modal",
                          modifiers: { "master-invoice-modal": true }
                        }
                      ],
                      staticClass: "pull-right pl-3 btn-sm font-weight-bold",
                      attrs: {
                        disabled: _vm.afes.length < 1,
                        variant: "primary"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mt-2 d-none d-sm-inline mr-1" },
                        [_vm._v("View Master Invoice ")]
                      ),
                      _c("i", { staticClass: "fas fa-search" })
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3 clearfix mb-4" },
            [
              _c(
                "b-col",
                [_c("VendorDetails", { attrs: { invoice: _vm.invoice } })],
                1
              ),
              _c(
                "b-col",
                [
                  _c("CompanyDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      company_name: _vm.company_name
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("InvoiceDetails", {
                attrs: {
                  invoice: _vm.invoice,
                  vwref: _vm.vwref,
                  userData: _vm.userData,
                  toggleVselect: true,
                  uwiData: _vm.uwiData,
                  serviceData: _vm.serviceData,
                  afes: _vm.afes
                },
                on: {
                  removeAll: function($event) {
                    return _vm.removeAll()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "service-tax-modal",
              staticClass: "service-tax-modal",
              attrs: {
                centered: "",
                size: "lg",
                "hide-footer": "",
                title: "Applied Taxes",
                id: "service-tax-modal"
              }
            },
            [
              _c("table", { staticClass: "tax-table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "tax-action-th" }),
                    _c("th", [_vm._v("Tax Name")]),
                    _c("th", [_vm._v("Tax Percentage")]),
                    _c("th", [_vm._v("Tax Amount")])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.selectedServiceTax.applied_taxes, function(
                    val,
                    i
                  ) {
                    return _c("tr", [
                      _c("td", { staticClass: "tax-action" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: val.isApplied,
                              expression: "val.isApplied"
                            }
                          ],
                          attrs: { type: "checkbox", disabled: "" },
                          domProps: {
                            checked: val.isApplied,
                            checked: Array.isArray(val.isApplied)
                              ? _vm._i(val.isApplied, null) > -1
                              : val.isApplied
                          },
                          on: {
                            change: function($event) {
                              var $$a = val.isApplied,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      val,
                                      "isApplied",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      val,
                                      "isApplied",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(val, "isApplied", $$c)
                              }
                            }
                          }
                        })
                      ]),
                      _c("td", [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", readonly: "" },
                          domProps: { value: val.tax_name }
                        })
                      ]),
                      _c("td", [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "number", readonly: "" },
                          domProps: { value: val.percentage }
                        })
                      ]),
                      _c("td", [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", readonly: "" },
                          domProps: {
                            value: _vm.formatPrice(
                              Number(
                                ("" + _vm.selectedServiceTax.total).replaceAll(
                                  ",",
                                  ""
                                )
                              ) *
                                (Number(val.percentage) / 100)
                            )
                          }
                        })
                      ])
                    ])
                  }),
                  0
                )
              ])
            ]
          ),
          _c("h3", { staticClass: "d-inline-block" }, [_vm._v("Services")]),
          _vm.invoice.inv_passed == 0
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-danger float-right",
                  attrs: { type: "button", name: "button" },
                  on: {
                    click: function($event) {
                      return _vm.removeAll()
                    }
                  }
                },
                [
                  _c("strong", [
                    _vm._v("Remove All Invoice Codings "),
                    _c("i", { staticClass: "fa fa-times" })
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSortingEnabled,
                  expression: "isSortingEnabled"
                }
              ],
              staticClass: "btn btn-sm btn-warning mr-2 float-right",
              attrs: { type: "button", name: "button" },
              on: {
                click: function($event) {
                  return _vm.disableSorting()
                }
              }
            },
            [_c("strong", [_vm._v("Disable Sorting")])]
          ),
          _c(
            "table",
            { attrs: { id: "Service-table" } },
            [
              _c("thead", [
                _c("tr", { attrs: { id: "Service-table-tr" } }, [
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n              Service\n              "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "name" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("name", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "name" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("name", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n              Code\n              "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "type" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("type", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "type" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("type", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v(
                        "\n              Start - End Date\n              "
                      ),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "dates" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("dates", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "dates" &&
                            !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("dates", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n              UOM\n              "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "uom" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("uom", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "uom" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("uom", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n              Price/Unit\n              "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "pu" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("pu", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "pu" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("pu", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v(
                        "\n              Discounted Price/unit\n              "
                      ),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] ===
                              "discounted_price_per_unit" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData(
                              "discounted_price_per_unit",
                              true
                            )
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] ===
                              "discounted_price_per_unit" && !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData(
                              "discounted_price_per_unit",
                              false
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n              Quantity\n              "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "quantity" &&
                            _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("quantity", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "quantity" &&
                            !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("quantity", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n              Discount(%)\n              "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "discount" &&
                            _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("discount", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "discount" &&
                            !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("discount", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [
                      _vm._v("\n              Total\n              "),
                      _c("i", {
                        staticClass: "fas fa-arrow-up mr-1",
                        style: {
                          color:
                            _vm.sortedValue[0] === "total" && _vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("total", true)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "fas fa-arrow-down",
                        style: {
                          color:
                            _vm.sortedValue[0] === "total" &&
                            !_vm.sortedValue[1]
                              ? "cornflowerblue !important"
                              : "white"
                        },
                        on: {
                          click: function($event) {
                            return _vm.sortData("total", false)
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "background-color": "#464646",
                        cursor: "pointer"
                      }
                    },
                    [_vm._v("\n              Applied Taxes\n            ")]
                  ),
                  _c("th", { staticStyle: { "background-color": "#464646" } }, [
                    _vm._v("Action")
                  ])
                ])
              ]),
              _vm._l(_vm.unassigned, function(service) {
                return _c("tbody", [
                  _c(
                    "tr",
                    {
                      class: {
                        highlightService: _vm.highlightServices.includes(
                          service.vsid
                        )
                      },
                      staticStyle: { cursor: "pointer" },
                      style: {
                        backgroundColor:
                          service.isflexible !== "N"
                            ? "cornsilk !important"
                            : "#F8F8FF"
                      },
                      attrs: { draggable: "", id: "Service-table-tr" },
                      on: {
                        dragover: function($event) {
                          $event.preventDefault()
                          return _vm.dragOver(
                            $event,
                            service,
                            service.isflexible
                          )
                        },
                        drop: function($event) {
                          return _vm.serviceOnDrop(service, null)
                        },
                        dragstart: function($event) {
                          return _vm.afeDragStart(null, service)
                        }
                      }
                    },
                    [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(service.name) +
                            "\n            "
                        )
                      ]),
                      _c("td", [_c("span", [_vm._v(_vm._s(service.type))])]),
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("moment")(service.start_date, "MM/DD/YYYY")
                            ) +
                            " -\n              " +
                            _vm._s(
                              _vm._f("moment")(service.end_date, "MM/DD/YYYY")
                            ) +
                            " "
                        ),
                        _c("br"),
                        _c("span", { staticStyle: { "font-size": "10px" } }, [
                          _vm._v(
                            "(" +
                              _vm._s(
                                parseInt(
                                  _vm
                                    .$moment(service.end_date)
                                    .diff(
                                      _vm.$moment(service.start_date),
                                      "days"
                                    )
                                ) + 1
                              ) +
                              "\n                Days)"
                          )
                        ])
                      ]),
                      _c("td", [_c("span", [_vm._v(_vm._s(service.uom))])]),
                      _c("td", [
                        _c("span", [
                          _vm._v("$" + _vm._s(_vm.formatPrice(service.pu)))
                        ])
                      ]),
                      _c("td", [
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                service.discount
                                  ? "$" +
                                      _vm._discount_price_per_unit(
                                        service.discount,
                                        service.pu
                                      )
                                  : service.name
                                  ? "$" + _vm.formatPrice(service.pu)
                                  : null
                              ) +
                              "\n              "
                          )
                        ])
                      ]),
                      _c("td", [
                        _c("span", [
                          _vm._v(_vm._s(parseFloat(service.quantity)))
                        ]),
                        /day|Day/.test(service.uom) &&
                        _vm
                          .$moment(service.end_date)
                          .diff(_vm.$moment(service.start_date), "days") +
                          1 !=
                          service.quantity
                          ? _c("span", { staticClass: "ml-1" }, [
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle",
                                attrs: {
                                  title:
                                    "Quantity does not equal sum between start and end dates (" +
                                    (parseInt(
                                      _vm
                                        .$moment(service.end_date)
                                        .diff(
                                          _vm.$moment(service.start_date),
                                          "days"
                                        )
                                    ) +
                                      1) +
                                    ")!"
                                }
                              })
                            ])
                          : _vm._e()
                      ]),
                      _c("td", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatPrice(service.discount)) + "%"
                          )
                        ])
                      ]),
                      _c("td", [
                        _c("span", [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm.formatPrice(
                                  service.quantity * service.pu -
                                    (service.discount / 100) *
                                      service.quantity *
                                      service.pu
                                )
                              )
                          )
                        ])
                      ]),
                      _c(
                        "td",
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: { width: "max-content" },
                              attrs: { size: "sm", variant: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.openServiceTax(service)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "Applied\n                Taxes\n              "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticStyle: { width: "100px" } },
                        [
                          _vm.invoice.inv_passed == 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "add-coding",
                                  attrs: {
                                    variant: "success",
                                    size: "sm",
                                    disabled: _vm.disableEdits == 1
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addNewUnassignedAfeLine(
                                        service,
                                        false
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Add Coding")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      style: {
                        display:
                          service.comment.replace(/\s/g, "").length > 0
                            ? "contents"
                            : "none"
                      }
                    },
                    [
                      _c("td", { attrs: { colspan: "100%" } }, [
                        _c("span", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: service.comment,
                                expression: "service.comment"
                              }
                            ],
                            staticClass: "form-control mb-3 d-block mt-3",
                            attrs: {
                              readonly: "",
                              placeholder: "Add comments"
                            },
                            domProps: { value: service.comment },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  service,
                                  "comment",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      class: {
                        createAfe: service != _vm.createdUnassignedServiceLine
                      },
                      style: {
                        display:
                          service != _vm.createdUnassignedServiceLine
                            ? "none"
                            : "table-row"
                      }
                    },
                    [
                      _c(
                        "td",
                        {
                          attrs: { colspan: "100%", id: "Service-table-child" }
                        },
                        [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", { attrs: { id: "Service-table-tr" } }, [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-size": "11px !important",
                                      "font-weight": "revert"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      AFE or Cost Center / Well\n                    "
                                    )
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-size": "11px !important",
                                      "font-weight": "revert"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Cost Code Chain\n                    "
                                    )
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-size": "11px !important",
                                      "font-weight": "revert"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Cost Code or coding Percentage %\n                      "
                                    ),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true }
                                        }
                                      ],
                                      staticClass: "fa fa-question-circle",
                                      attrs: {
                                        title:
                                          "AFE or Cost Code or coding Percentage"
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-size": "11px !important",
                                      "font-weight": "revert"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                      subtotal\n                    "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    service == _vm.createdUnassignedServiceLine
                                      ? _c("v-select", {
                                          staticStyle: { width: "345px" },
                                          attrs: {
                                            clearable: false,
                                            label: "afenum",
                                            options: _vm.AfeData,
                                            value: _vm.afenum,
                                            selectable: function(option) {
                                              return (
                                                option.afenum !=
                                                "========================================"
                                              )
                                            },
                                            id: "v-select1"
                                          },
                                          model: {
                                            value: _vm.createAfenum,
                                            callback: function($$v) {
                                              _vm.createAfenum = $$v
                                            },
                                            expression: "createAfenum"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c("v-select", {
                                      staticStyle: { width: "345px" },
                                      attrs: {
                                        clearable: false,
                                        options: _vm.paginated,
                                        label: "costcodechainid",
                                        filterable: false,
                                        selectable: function(option) {
                                          return ![-2, -3].includes(
                                            option.costcodechainid
                                          )
                                        },
                                        id: "v-select1"
                                      },
                                      on: {
                                        search: function(query) {
                                          return (_vm.search = query)
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "option",
                                            fn: function(option) {
                                              return [
                                                option.costcodechainid == -2
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "black",
                                                          padding: "5px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "=================================="
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                option.costcodechainid == -3
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "black",
                                                          padding: "5px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "=================================="
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                ![-2, -3].includes(
                                                  option.costcodechainid
                                                )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          option.costcode1
                                                        ) +
                                                          "-" +
                                                          _vm._s(
                                                            option.costcode2
                                                          ) +
                                                          "-" +
                                                          _vm._s(
                                                            option.costcode3
                                                          )
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "selected-option",
                                            fn: function(ref) {
                                              var costcode1 = ref.costcode1
                                              var costcode2 = ref.costcode2
                                              var costcode3 = ref.costcode3
                                              var option = ref.option
                                              var deselect = ref.deselect
                                              var disabled = ref.disabled
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(costcode1) +
                                                      "-" +
                                                      _vm._s(costcode2) +
                                                      "-" +
                                                      _vm._s(costcode3)
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.CC_data,
                                        callback: function($$v) {
                                          _vm.CC_data = $$v
                                        },
                                        expression: "CC_data"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.percentage,
                                        expression: "percentage"
                                      }
                                    ],
                                    staticClass: "rt-percent",
                                    attrs: { type: "number", step: "any" },
                                    domProps: { value: _vm.percentage },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.percentage = $event.target.value
                                      }
                                    }
                                  })
                                ]),
                                _c("td", [
                                  _c("input", {
                                    staticClass: "rt-percent form-control mr-2",
                                    attrs: { type: "text", readonly: "" },
                                    domProps: {
                                      value: _vm.formatPrice(
                                        Number(service.pu) *
                                          (Number(_vm.percentage) / 100)
                                      )
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _c(
                            "div",
                            { attrs: { id: "afe-filter" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-3",
                                  attrs: { variant: "success", size: "sm" },
                                  on: {
                                    mouseover: function($event) {
                                      return _vm.highlightService([service])
                                    },
                                    mouseleave: function($event) {
                                      return _vm.removeHighlightService()
                                    },
                                    click: function($event) {
                                      return _vm.addAfeUnassignedLine(service)
                                    }
                                  }
                                },
                                [
                                  _vm._v("Add "),
                                  _c("i", {
                                    staticClass: "ml-2 fa fa-plus-circle"
                                  })
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-3",
                                  attrs: { variant: "success", size: "sm" },
                                  on: {
                                    mouseover: function($event) {
                                      return _vm.highlightService(
                                        _vm.serviceData
                                      )
                                    },
                                    mouseleave: function($event) {
                                      return _vm.removeHighlightService()
                                    },
                                    click: function($event) {
                                      return _vm.addAfeToAllInvoiceServices(
                                        false
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v("Add To All"),
                                  _c("i", {
                                    staticClass: "fa fa-plus-circle ml-2"
                                  })
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  attrs: {
                                    variant: "danger",
                                    size: "sm",
                                    title: "AFE or Cost Center / Well Cancel"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelAfe()
                                    }
                                  }
                                },
                                [
                                  _vm._v("Cancel"),
                                  _c("i", {
                                    staticClass: "ml-2 fa fa-times-circle"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ])
              }),
              _vm._l(_vm.merged, function(afe, index) {
                return _c(
                  "tbody",
                  [
                    _vm._l(afe, function(service, serviceIndex) {
                      return _c(
                        "tr",
                        {
                          class: {
                            isTop: service.isTop,
                            highlightService: _vm.highlightServices.includes(
                              service.vsid
                            )
                          },
                          staticStyle: {
                            color: "white !important",
                            "border-left": "1.02px solid red",
                            "border-right": "1.02px solid red"
                          },
                          style: {
                            backgroundColor:
                              service.isflexible !== "N"
                                ? !_vm.afePercentage.includes(afe[0].vsid)
                                  ? "darkslategrey"
                                  : "#ff4d4d"
                                : !_vm.afePercentage.includes(afe[0].vsid)
                                ? "#484848"
                                : "#ff4d4d"
                          },
                          attrs: { id: "Service-table-tr" },
                          on: {
                            dragover: function($event) {
                              $event.preventDefault()
                              return _vm.dragOver(
                                $event,
                                service,
                                service.serviceid.isflexible
                              )
                            },
                            drop: function($event) {
                              return _vm.serviceOnDrop(service, null)
                            }
                          }
                        },
                        [
                          _c("td", [
                            _c("span", [_vm._v(_vm._s(service.name))])
                          ]),
                          _c("td", [
                            _c("span", [_vm._v(_vm._s(service.type))])
                          ]),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    service.start_date,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " -\n              " +
                                _vm._s(
                                  _vm._f("moment")(
                                    service.end_date,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " "
                            ),
                            _c("br"),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "10px" } },
                              [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      parseInt(
                                        _vm
                                          .$moment(service.end_date)
                                          .diff(
                                            _vm.$moment(service.start_date),
                                            "days"
                                          )
                                      ) + 1
                                    ) +
                                    "\n                Days)"
                                )
                              ]
                            )
                          ]),
                          _c("td", [_c("span", [_vm._v(_vm._s(service.uom))])]),
                          _c("td", [
                            _c("span", [
                              _vm._v("$" + _vm._s(_vm.formatPrice(service.pu)))
                            ])
                          ]),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    service.discount
                                      ? "$" +
                                          _vm._discount_price_per_unit(
                                            service.discount,
                                            service.pu
                                          )
                                      : service.name
                                      ? "$" + _vm.formatPrice(service.pu)
                                      : null
                                  ) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _c("td", [
                            _c("span", [
                              _vm._v(_vm._s(parseFloat(service.quantity)))
                            ]),
                            /day|Day/.test(service.uom) &&
                            _vm
                              .$moment(service.end_date)
                              .diff(_vm.$moment(service.start_date), "days") +
                              1 !=
                              service.quantity
                              ? _c("span", { staticClass: "ml-1" }, [
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        modifiers: { hover: true }
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle",
                                    attrs: {
                                      title:
                                        "Quantity does not equal sum between start and end dates (" +
                                        (parseInt(
                                          _vm
                                            .$moment(service.end_date)
                                            .diff(
                                              _vm.$moment(service.start_date),
                                              "days"
                                            )
                                        ) +
                                          1) +
                                        ")!"
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.formatPrice(service.discount)) + "%"
                              )
                            ])
                          ]),
                          _c("td", [
                            _c("span", [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.formatPrice(
                                      service.quantity * service.pu -
                                        (service.discount / 100) *
                                          service.quantity *
                                          service.pu
                                    )
                                  )
                              )
                            ])
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "b-button",
                                {
                                  staticStyle: { width: "max-content" },
                                  attrs: { size: "sm", variant: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openServiceTax(service)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "Applied\n                Taxes\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _vm.invoice.inv_passed == 0
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "add-coding",
                                      attrs: {
                                        variant: "success",
                                        size: "sm",
                                        disabled: _vm.disableEdits == 1
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addNewAfeLine(afe, service)
                                        }
                                      }
                                    },
                                    [_vm._v("Add Coding")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    _c(
                      "tr",
                      {
                        style: { display: afe[0].comment ? "contents" : "none" }
                      },
                      [
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "border-left": "1.02px solid red",
                              "border-right": "1.02px solid red"
                            },
                            attrs: { colspan: "100%" }
                          },
                          [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: afe[0].comment,
                                  expression: "afe[0].comment"
                                }
                              ],
                              staticClass: "form-control mb-3 d-block mt-3",
                              attrs: {
                                readonly: "",
                                placeholder: "Add comments"
                              },
                              domProps: { value: afe[0].comment },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    afe[0],
                                    "comment",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "tr",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              afe[0].show_afe || afe === _vm.createdServiceLine,
                            expression:
                              "afe[0].show_afe || afe === createdServiceLine"
                          }
                        ]
                      },
                      [
                        _c(
                          "td",
                          {
                            attrs: {
                              colspan: "100%",
                              id: "Service-table-child"
                            }
                          },
                          [
                            _c(
                              "table",
                              { staticClass: "table no-bg afenum mb-0" },
                              [
                                _c(
                                  "thead",
                                  {
                                    staticStyle: {
                                      "border-left": "1.02px solid red",
                                      "border-right": "1.02px solid red"
                                    }
                                  },
                                  [
                                    _c("tr", [
                                      _c("th", [
                                        _c("strong", [
                                          _vm._v("AFE or Cost Center / Well")
                                        ])
                                      ]),
                                      _c("th", [
                                        _c("strong", [
                                          _vm._v(" Cost Code Chain ")
                                        ])
                                      ]),
                                      _c("th", [
                                        _c("strong", [
                                          _vm._v(" Coding Percentage % ")
                                        ])
                                      ]),
                                      _c("th", [
                                        _c("strong", [_vm._v("subtotal")])
                                      ]),
                                      _c("th", { attrs: { id: "editAfe" } }, [
                                        _vm.invoice.inv_passed == 0
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-minus-circle",
                                              staticStyle: {
                                                cursor: "pointer",
                                                "margin-left": "9px",
                                                "font-size": "medium",
                                                color: "red",
                                                "margin-top": "8px"
                                              },
                                              attrs: {
                                                disabled: _vm.disableEdits == 1
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeAllAfe(
                                                    afe[0]
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ])
                                    ])
                                  ]
                                ),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(afe[0].afes, function(line, aIndex) {
                                      return _c(
                                        "tr",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: afe[0].show_afe,
                                              expression: "afe[0].show_afe"
                                            }
                                          ],
                                          class: {
                                            editingAfe:
                                              line == _vm.editedAfeLine,
                                            afeBorder:
                                              afe[0].show_afe &&
                                              aIndex === afe[0].afes.length - 1
                                          },
                                          staticStyle: {
                                            "border-left": "1.02px solid red",
                                            "border-right": "1.02px solid red"
                                          },
                                          attrs: {
                                            id: "AfeDragStart",
                                            draggable: ""
                                          },
                                          on: {
                                            dragover: function($event) {
                                              $event.preventDefault()
                                              return _vm.dragOver(afe[0], line)
                                            },
                                            drop: function($event) {
                                              return _vm.serviceOnDrop(
                                                afe[0],
                                                line
                                              )
                                            },
                                            dragstart: function($event) {
                                              return _vm.afeDragStart(
                                                line,
                                                afe[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeLine(
                                                      line,
                                                      index,
                                                      aIndex,
                                                      _vm.afes,
                                                      afe
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-chevron-circle-right"
                                                }),
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(line.afenum) +
                                                    "\n                      "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "edit" },
                                              [
                                                line == _vm.editedAfeLine
                                                  ? _c("v-select", {
                                                      staticStyle: {
                                                        width: "345px"
                                                      },
                                                      attrs: {
                                                        clearable: false,
                                                        label: "afenum",
                                                        options:
                                                          _vm.serviceChainData,
                                                        selectable: function(
                                                          option
                                                        ) {
                                                          return (
                                                            option.afenum !=
                                                            "========================================"
                                                          )
                                                        },
                                                        value: _vm.afenum,
                                                        id: "v-select1"
                                                      },
                                                      model: {
                                                        value: line.afenum,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            line,
                                                            "afenum",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "line.afenum"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeLine(
                                                      line,
                                                      index,
                                                      aIndex,
                                                      _vm.afes,
                                                      afe
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(line.ccone_code) +
                                                    " -- " +
                                                    _vm._s(line.cctwo_code) +
                                                    " --\n                        " +
                                                    _vm._s(line.ccthree_code) +
                                                    "\n                      "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "edit" },
                                              [
                                                line == _vm.editedAfeLine
                                                  ? _c("v-select", {
                                                      staticStyle: {
                                                        width: "345px"
                                                      },
                                                      attrs: {
                                                        clearable: false,
                                                        options:
                                                          _vm.afeAttachedCostCodes,
                                                        filterable: false,
                                                        label:
                                                          "costcodechainid",
                                                        selectable: function(
                                                          option
                                                        ) {
                                                          return ![
                                                            -2,
                                                            -3
                                                          ].includes(
                                                            option.costcodechainid
                                                          )
                                                        },
                                                        id: "v-select1"
                                                      },
                                                      on: {
                                                        search: function(
                                                          query
                                                        ) {
                                                          return (_vm.search = query)
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "option",
                                                            fn: function(
                                                              option
                                                            ) {
                                                              return [
                                                                option.costcodechainid ==
                                                                -2
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle: {
                                                                          color:
                                                                            "black",
                                                                          padding:
                                                                            "5px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "=================================="
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                option.costcodechainid ==
                                                                -3
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle: {
                                                                          color:
                                                                            "black",
                                                                          padding:
                                                                            "5px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "=================================="
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                ![
                                                                  -2,
                                                                  -3
                                                                ].includes(
                                                                  option.costcodechainid
                                                                )
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          option.costcode1
                                                                        ) +
                                                                          "-" +
                                                                          _vm._s(
                                                                            option.costcode2
                                                                          ) +
                                                                          "-" +
                                                                          _vm._s(
                                                                            option.costcode3
                                                                          )
                                                                      )
                                                                    ])
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key:
                                                              "selected-option",
                                                            fn: function(ref) {
                                                              var costcode1 =
                                                                ref.costcode1
                                                              var costcode2 =
                                                                ref.costcode2
                                                              var costcode3 =
                                                                ref.costcode3
                                                              var option =
                                                                ref.option
                                                              var deselect =
                                                                ref.deselect
                                                              var disabled =
                                                                ref.disabled
                                                              return [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      costcode1
                                                                    ) +
                                                                      "-" +
                                                                      _vm._s(
                                                                        costcode2
                                                                      ) +
                                                                      "-" +
                                                                      _vm._s(
                                                                        costcode3
                                                                      )
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value: _vm.CC_data,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.CC_data = $$v
                                                        },
                                                        expression: "CC_data"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeLine(
                                                      line,
                                                      index,
                                                      aIndex,
                                                      _vm.afes,
                                                      afe
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(line.percentage) +
                                                    "\n                      "
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "edit" }, [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.percentage,
                                                    expression: "percentage"
                                                  }
                                                ],
                                                staticClass: "rt-percent",
                                                attrs: {
                                                  type: "number",
                                                  step: "any"
                                                },
                                                domProps: {
                                                  value: _vm.percentage
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.percentage =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ])
                                          ]),
                                          _c(
                                            "td",
                                            { attrs: { id: "editAfe" } },
                                            [
                                              _c("input", {
                                                staticClass:
                                                  "rt-percent form-control view",
                                                attrs: {
                                                  type: "text",
                                                  readonly: ""
                                                },
                                                domProps: {
                                                  value: _vm.formatPrice(
                                                    Number(
                                                      afe[0].combined_total
                                                    ) *
                                                      (Number(line.percentage) /
                                                        100)
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeLine(
                                                      line,
                                                      index,
                                                      aIndex,
                                                      _vm.afes,
                                                      afe
                                                    )
                                                  }
                                                }
                                              }),
                                              _c("input", {
                                                staticClass:
                                                  "rt-percent form-control edit",
                                                attrs: {
                                                  type: "text",
                                                  readonly: ""
                                                },
                                                domProps: {
                                                  value: _vm.formatPrice(
                                                    Number(
                                                      afe[0].combined_total
                                                    ) *
                                                      (Number(_vm.percentage) /
                                                        100)
                                                  )
                                                }
                                              }),
                                              line == _vm.editedAfeLine
                                                ? _c(
                                                    "b-overlay",
                                                    {
                                                      staticClass:
                                                        "mt-3 d-flex justify-content-end",
                                                      attrs: {
                                                        show: _vm.editAfe == 0,
                                                        rounded: "sm"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "mr-3 edit",
                                                          staticStyle: {
                                                            color: "green",
                                                            "font-size":
                                                              "medium"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.editAfe = 0
                                                              _vm.editAddAfeData(
                                                                line,
                                                                afe,
                                                                index,
                                                                aIndex,
                                                                _vm.afes
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("b", [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-check-circle",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "x-large"
                                                              }
                                                            })
                                                          ])
                                                        ]
                                                      ),
                                                      line == _vm.editedAfeLine
                                                        ? _c(
                                                            "span",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover",
                                                                  modifiers: {
                                                                    hover: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "edit",
                                                              staticStyle: {
                                                                color: "#f74f4f"
                                                              },
                                                              attrs: {
                                                                title:
                                                                  "AFE or Cost Center / Well Cancel"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.cancelEditAfe(
                                                                    line,
                                                                    afe,
                                                                    index,
                                                                    aIndex,
                                                                    _vm.afes
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fa fa-times-circle",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "x-large"
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "view",
                                              attrs: { id: "editAfe" }
                                            },
                                            [
                                              _vm.invoice.inv_passed == 0
                                                ? _c("i", {
                                                    staticClass:
                                                      "fas fa-minus-circle",
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                      "margin-left": "9px",
                                                      "font-size": "medium",
                                                      color: "red",
                                                      "margin-top": "8px"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteServiceAfe(
                                                          line,
                                                          afe[0]
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _c(
                                      "tr",
                                      {
                                        staticClass: "rt-wss-create-afe",
                                        class: {
                                          createAfe:
                                            afe != _vm.createdServiceLine
                                        }
                                      },
                                      [
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "show" },
                                            [
                                              afe == _vm.createdServiceLine
                                                ? _c("v-select", {
                                                    staticStyle: {
                                                      width: "345px"
                                                    },
                                                    attrs: {
                                                      clearable: false,
                                                      label: "afenum",
                                                      options:
                                                        _vm.createAfeData,
                                                      value: _vm.afenum,
                                                      selectable: function(
                                                        option
                                                      ) {
                                                        return (
                                                          option.afenum !=
                                                          "========================================"
                                                        )
                                                      },
                                                      id: "v-select1"
                                                    },
                                                    model: {
                                                      value: _vm.createAfenum,
                                                      callback: function($$v) {
                                                        _vm.createAfenum = $$v
                                                      },
                                                      expression: "createAfenum"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "show" },
                                            [
                                              _c("v-select", {
                                                staticStyle: { width: "345px" },
                                                attrs: {
                                                  clearable: false,
                                                  options: _vm.paginated,
                                                  filterable: false,
                                                  label: "costcodechainid",
                                                  id: "v-select1"
                                                },
                                                on: {
                                                  search: function(query) {
                                                    return (_vm.search = query)
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "option",
                                                      fn: function(option) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.costcode1
                                                              )
                                                            )
                                                          ]),
                                                          _c("span", [
                                                            _vm._v(
                                                              "-" +
                                                                _vm._s(
                                                                  option.costcode2
                                                                )
                                                            )
                                                          ]),
                                                          _c("span", [
                                                            _vm._v(
                                                              "-" +
                                                                _vm._s(
                                                                  option.costcode3
                                                                )
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "selected-option",
                                                      fn: function(ref) {
                                                        var costcode1 =
                                                          ref.costcode1
                                                        var costcode2 =
                                                          ref.costcode2
                                                        var costcode3 =
                                                          ref.costcode3
                                                        var option = ref.option
                                                        var deselect =
                                                          ref.deselect
                                                        var multiple =
                                                          ref.multiple
                                                        var disabled =
                                                          ref.disabled
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(costcode1)
                                                            )
                                                          ]),
                                                          _c("span", [
                                                            _vm._v(
                                                              "-" +
                                                                _vm._s(
                                                                  costcode2
                                                                )
                                                            )
                                                          ]),
                                                          _c("span", [
                                                            _vm._v(
                                                              "-" +
                                                                _vm._s(
                                                                  costcode3
                                                                )
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.CC_data,
                                                  callback: function($$v) {
                                                    _vm.CC_data = $$v
                                                  },
                                                  expression: "CC_data"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]),
                                        _c("td", [
                                          _c("div", { staticClass: "show" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.percentage,
                                                  expression: "percentage"
                                                }
                                              ],
                                              staticClass: "rt-percent",
                                              attrs: {
                                                type: "number",
                                                step: "any"
                                              },
                                              domProps: {
                                                value: _vm.percentage
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.percentage =
                                                    $event.target.value
                                                }
                                              }
                                            })
                                          ])
                                        ]),
                                        _c("td", [
                                          _c("input", {
                                            staticClass:
                                              "rt-percent form-control view",
                                            attrs: {
                                              type: "text",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value: _vm.formatPrice(
                                                Number(
                                                  afe[0].quantity * afe[0].pu -
                                                    (afe[0].discount / 100) *
                                                      afe[0].quantity *
                                                      afe[0].pu
                                                ) *
                                                  (Number(_vm.percentage) / 100)
                                              )
                                            }
                                          })
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "tr",
                                      {
                                        class: {
                                          createAfe:
                                            afe != _vm.createdServiceLine
                                        },
                                        style: {
                                          display:
                                            afe != _vm.createdServiceLine
                                              ? "none"
                                              : "revert"
                                        }
                                      },
                                      [
                                        _c(
                                          "td",
                                          { attrs: { colspan: "100%" } },
                                          [
                                            _c(
                                              "div",
                                              { attrs: { id: "afe-filter" } },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      variant: "success",
                                                      size: "sm"
                                                    },
                                                    on: {
                                                      mouseover: function(
                                                        $event
                                                      ) {
                                                        return _vm.highlightService(
                                                          [afe[0]]
                                                        )
                                                      },
                                                      mouseleave: function(
                                                        $event
                                                      ) {
                                                        return _vm.removeHighlightService()
                                                      },
                                                      click: function($event) {
                                                        return _vm.addAfeLine(
                                                          afe,
                                                          _vm.line
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Add "),
                                                    _c("i", {
                                                      staticClass:
                                                        "ml-2 fa fa-plus-circle"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      variant: "success",
                                                      size: "sm"
                                                    },
                                                    on: {
                                                      mouseover: function(
                                                        $event
                                                      ) {
                                                        return _vm.highlightService(
                                                          _vm.serviceData
                                                        )
                                                      },
                                                      mouseleave: function(
                                                        $event
                                                      ) {
                                                        return _vm.removeHighlightService()
                                                      },
                                                      click: function($event) {
                                                        return _vm.addAfeToAllInvoiceServices(
                                                          false
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Add To\n                          All"
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-plus-circle ml-2"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass: "mr-3",
                                                    attrs: {
                                                      variant: "warning",
                                                      size: "sm"
                                                    },
                                                    on: {
                                                      mouseover: function(
                                                        $event
                                                      ) {
                                                        _vm.highlightService(
                                                          _vm.serviceData.filter(
                                                            function(val) {
                                                              return afe[0].same_coding.includes(
                                                                val.vsid
                                                              )
                                                            }
                                                          )
                                                        )
                                                      },
                                                      mouseleave: function(
                                                        $event
                                                      ) {
                                                        return _vm.removeHighlightService()
                                                      },
                                                      click: function($event) {
                                                        return _vm.addAfeToAllInvoiceServices(
                                                          true,
                                                          afe[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Add To Group "),
                                                    _c("i", {
                                                      staticClass:
                                                        "mr-3 fa fa-plus-circle"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true
                                                        }
                                                      }
                                                    ],
                                                    attrs: {
                                                      variant: "danger",
                                                      size: "sm",
                                                      title:
                                                        "AFE or Cost Center / Well Cancel"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.cancelAfe()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v("Cancel"),
                                                    _c("i", {
                                                      staticClass:
                                                        "ml-2 fa fa-times-circle"
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  2
                )
              })
            ],
            2
          ),
          _c("InvoiceTotals", {
            attrs: {
              invoice: _vm.invoice,
              serviceData: _vm.serviceData,
              currency: _vm.currency
            }
          }),
          _vm.set === 1
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _vm.invoice.vendor_note
                    ? _c("h5", [_vm._v("Vendor Comment :")])
                    : _vm._e(),
                  _vm.invoice.vendor_note
                    ? _c(
                        "textarea",
                        {
                          staticClass: "form-control mb-3",
                          attrs: { readonly: "" }
                        },
                        [_vm._v(_vm._s(_vm.invoice.vendor_note))]
                      )
                    : _vm._e(),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "add-comments",
                        "no-close-on-backdrop": true,
                        title: "Add Comment",
                        size: "xl"
                      },
                      on: {
                        ok: function($event) {
                          return _vm.switchAction()
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "modal-footer",
                            fn: function(ref) {
                              var ok = ref.ok
                              var cancel = ref.cancel
                              return [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      size: "sm",
                                      variant: "success",
                                      disabled: _vm.comments.trim().length === 0
                                    },
                                    on: {
                                      click: function($event) {
                                        return ok()
                                      }
                                    }
                                  },
                                  [_vm._v("\n              OK\n            ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    attrs: { size: "sm", variant: "danger" },
                                    on: {
                                      click: function($event) {
                                        return cancel()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Cancel\n            "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        707388114
                      )
                    },
                    [
                      _c("h5", [_vm._v("Please add a comment.")]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.comments,
                            expression: "comments"
                          }
                        ],
                        staticClass: "w-100 rounded border mt-2",
                        attrs: { name: "comments", rows: "3" },
                        domProps: { value: _vm.comments },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.comments = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "i",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.comments.trim().length === 0,
                              expression: "comments.trim().length === 0"
                            }
                          ],
                          staticClass: "fa fa-exclamation-triangle"
                        },
                        [_vm._v("Comment is require.")]
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.add-comments",
                          modifiers: { "add-comments": true }
                        }
                      ],
                      staticClass: "btn btn-danger mr-4 mb-4",
                      on: {
                        click: function($event) {
                          _vm.submissionAction = 0
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-times-circle" }),
                      _vm._v(" Reject\n        ")
                    ]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.add-comments",
                          modifiers: { "add-comments": true }
                        }
                      ],
                      staticClass: "btn btn-success mb-4 mr-4",
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          _vm.submissionAction = 1
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-check" }),
                      _vm._v(" Approve\n        ")
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceError,
                  expression: "invoiceError"
                }
              ],
              staticClass: "d-block"
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.invoiceError,
                    expression: "invoiceError"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceError,
                      expression: "invoiceError"
                    }
                  ],
                  staticClass: "help"
                },
                [
                  _vm._v(
                    "Invoice\n          Error. Please fix invoice or contact admin."
                  )
                ]
              )
            ]
          ),
          _c("hr"),
          _c("InvoiceHistory", { attrs: { history: _vm.invoice } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }