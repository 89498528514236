<template>
  <div class="animated fadeIn">
    <div class="animated fadeIn">
      <!-- <div v-if="sortedServiceData.length == 0">
        <h4 class="text-center mt-3">No Estimates Found</h4>
        <p class="text-center mt-3"><router-link class="btn btn-success" :to="{ name: 'WSS Estimates'}"><i class="fa fa-plus"></i> Create Estimate</router-link></p>
      </div> -->
      <div v-if="sortedServiceData.length > 0">
        <div v-for="(service, index) in sortedServiceData" v-cloak>
          <table class="table table-striped">
            <thead>
              <tr class="e-service-rows">
                <th class="fit"></th>
                <th class="center service-name">Service</th>
                <th class="datepickers right">Start Date</th>
                <th class="datepickers right">End Date</th>
                <th class="uom-select">UOM <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></th>
                <th class="price center">Price/Unit</th>
                <th> Discounted Price/Unit</th>
                <th class="price center">Quantity</th>
                <th class="price center">Discount (%)</th>
                <th class="right">Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="e-service-rows" :class="{editing: service == editedLine}">
                <td class="d-xs-none d-lg-block fit border-0"><button type="button" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1 || service.service.length > 1" v-on:click="removeService(index, service.service[0].wsid)" class="btn btn-sm btn-danger"><i class="fa fa-minus"></i></button></td>
                <td class="center price v-align-middle position-relative">
                  <div @click="editData(service, index)">
                    {{service.service[0].serviceid.name}}
                  </div>
                </td>
                <td class="datepickers center v-align-middle">
                  <div class="view" @click="editData(service, index)">
                    {{service.service[0].start_date | moment("MM/DD/YYYY")}}
                  </div>
                  <div class="edit">
                    <datepicker format="MM/dd/yyyy" name="service.service[0].start_date" class="rt-date-picker" placeholder="Dropdown" v-validate="'required'" v-model="service.service[0].start_date"></datepicker>
                  </div>
                </td>
                <td class="datepickers center v-align-middle">
                  <div class="view" @click="editData(service, index)">
                    {{service.service[0].end_date | moment("MM/DD/YYYY")}}
                  </div>
                  <div class="edit">
                    <datepicker v-bind:class="{'date-danger': $moment(service.service[0].start_date).format('YYYY-MM-DD') < $moment(service.service[0].end_date).format('YYYY-MM-DD')}" format="MM/dd/yyyy" name="service.service[0].end_date" class="rt-date-picker" placeholder="Dropdown" v-validate="'required'" v-model="service.service[0].end_date"></datepicker>
                  </div>
                </td>

                <td class="left v-align-middle">
                  <div class="view" v-if="service.service[0].uom" @click="editData(service, index)">
                    {{service.service[0].uom[1]}}
                  </div>
                  <div class="edit">
                    <select v-validate="'required'" class="w-100 rt-select" @change = "Pu_toggler(service.servic[0],service.service[0].discount,service.service[0].uom[0])"
                    
                    name="service.service[0].uom" v-if="service.service[0].serviceid" :class="{'is-danger': errors.has('service.service[0].uom')}" v-model="service.service[0].uom" placeholder="Select">
                      <option :value="[service.service[0].serviceid.pu1, service.service[0].serviceid.uom1]">{{service.service[0].serviceid.uom1}}</option>
                      <option :value="[service.service[0].serviceid.pu2, service.service[0].serviceid.uom2]">{{service.service[0].serviceid.uom2}}</option>
                      <option :value="[service.service[0].serviceid.pu3, service.service[0].serviceid.uom3]">{{service.service[0].serviceid.uom3}}</option>
                    </select>
                  </div>
                </td>

                <td class="price left v-align-middle">
                  <div class="view" @click="editData(service, index)">
                    ${{formatPrice(service.service[0].pu)}}
                  </div>
                  <div class="edit">
                    <span v-if="service.service[0].uom">${{formatPrice(service.service[0].uom[0])}}</span>
                  </div>
                </td>


                <td class="price left v-align-middle">
                  <div class="view" @click="editData(service, index)">$
{{service.service[0].discounted_price_per_unit ? formatPrice(service.service[0].discounted_price_per_unit) :   _discount_price_per_unit(service.service[0].discount,service.service[0].uom[0])}}
   
                  </div>
                  <div class="edit">
                    <span v-if="service.service[0].uom">$

 <input v-if="service.service[0].serviceid.name && !service.service[0].discounted_price_per_unit" v-bind:value= " _discount_price_per_unit(service.service[0].discount,service.service[0].uom[0])">                       
 <input v-if = " service.service[0].serviceid.name && service.service[0].discounted_price_per_unit"  v-model ="service.service[0].discounted_price_per_unit"  @input="Discount(service.service[0],service.service[0].discounted_price_per_unit,service.service[0].uom[0])">
  
  </span>               
                 </div>
                </td>


                <td class="price left v-align-middle">
                  <div class="view" @click="editData(service, index)">
                    {{service.service[0].quantity}}
                  </div>
                  <div class="edit">
                    <input type="text" class="rt-round" v-model="service.service[0].quantity">
                  </div>
                </td>
                <td class="price left v-align-middle">
                  <div class="view" @click="editData(service, index)">
                    ${{formatPrice(service.service[0].discount/100 * service.service[0].pu * service.service[0].quantity)}} ({{service.service[0].discount}}%)
                  </div>
                  <div class="edit">
           
            <input type="text" v-if="service.service[0].serviceid.name" class="rt-round" 
v-model="service.service[0].discount" @input="discounted(service.service[0], service.service[0].discount,service.service[0].uom[0])">

                  </div>
                </td>
                <td class="right v-align-middle">
                  <div class="view" @click="editData(service, index)">
                    ${{service.service[0].total}}
                  </div>
                  <div class="edit">
                    <span v-if="service.service[0].uom">${{formatPrice((service.service[0].quantity * service.service[0].uom[0]) - ((service.service[0].discount/100) * service.service[0].quantity * service.service[0].uom[0]))}}</span>
                  </div>
                </td>
                <td class="right">
                  <button v-if="$moment(service.service[0].start_date).format('YYYY-MM-DD') <= $moment(service.service[0].end_date).format('YYYY-MM-DD') && service.service[0].quantity >= 0 && service.service[0].discount >= 0 && service.service[0].discount <= 100" class="edit rt-d-inline mr-2 btn btn-sm btn-primary" type="button" @click="editService(service.service[0])">
                    <i class="fa fa-edit"></i>
                  </button>
                  <b-button v-if="beforeEditAfeCacheCatch != 1 && beforeEditCacheCatch != 1" class="btn-sm" style="width: 100%; max-width: 62px;" v-b-toggle="'c-' + index" variant="primary">AFE'S <i class="fa fa-chevron-down"></i></b-button>
                </td>
              </tr>
            </tbody>
          </table>
          <b-collapse accordion="afe-collapse" v-bind:id="'c-' + index" class="mt-2">
            <table class="table table-striped">
            <thead>
              <tr>
                <th class="actions fit"></th>
                <th class="center">AFE #</th>
                <th class="center fit">% <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></th>
                <th class="center">Cost Code #1</th>
                <th class="center">Cost Code #2</th>
                <th class="center">Cost Code #3</th>
                <th class="center fit"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="v-align" :class="{editingAfe: afe == editedAfeLine}" v-for="(afe, aIndex) in service.service" v-if="afe.afenum">
                <td class="actions" v-b-tooltip.hover title="Remove AFE From Invoice"><button v-if="!afe.add" :disabled="beforeEditAfeCacheCatch == 1 || beforeEditCacheCatch == 1" type="button" v-on:click="removeAfe(afe.apwid)" class="fit btn btn-sm btn-danger"><i class="fa fa-minus-circle"></i></button><button v-if="afe.add == 1" type="button" v-on:click="cancelAfe()" class="btn btn-sm btn-danger"><i class="fa fa-times-circle"></i></button></td>
                <td class="left">
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    {{afe.afenum}}
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <v-select :clearable="false"
                      v-model="edit_afe"
                      label="afenum"
                      :options="serviceChainData"
                      :value="afenum"
                      @input="getcc1()"
                    ></v-select>
                  </div>
                  <div v-if="afe.add == 1">
                    <v-select :clearable="false"
                      v-model="add_afe"
                      label="afenum"
                      :options="serviceChainData"
                      :value="afenum"
                      :searchable="false"
                      @input="addcc1()"
                    ></v-select>
                  </div>
                </td>
                <td class="left fit">
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    {{afe.percentage}}
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <input class="rt-quantity" type="number" step="any" min="0" max="100" v-model="edit_percentage">
                  </div>
                  <div v-if="afe.add == 1">
                    <input class="rt-quantity" type="number" step="any" min="0" max="100" v-model="add_percentage">
                  </div>
                </td>
                <td class="left">
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    {{afe.ccone_code}}
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <v-select :clearable="false"
                      v-model="edit_ccone_code"
                      class="v-select-sm"
                      label="ccone_code"
                      :options="cc1Data"
                      :value="ccone_code"
                      @input="getcc2()"
                    ></v-select>
                  </div>
                  <div v-if="afe.add == 1">
                    <v-select :clearable="false"
                      v-model="add_ccone_code"
                      class="v-select-sm"
                      label="ccone_code"
                      :options="cc1Data"
                      :value="ccone_code"
                      :searchable="false"
                      @input="addcc2()"
                    ></v-select>
                  </div>
                </td>
                <td class="left">
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    {{afe.cctwo_code}}
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <v-select :clearable="false"
                      v-model="edit_cctwo_code"
                      class="v-select-sm"
                      label="cctwo_code"
                      :options="cc2Data"
                      :value="cctwo_code"
                      @input="getcc3()"
                    ></v-select>
                  </div>
                  <div v-if="afe.add == 1">
                    <v-select :clearable="false"
                      v-model="add_cctwo_code"
                      class="v-select-sm"
                      label="cctwo_code"
                      :options="cc2Data"
                      :value="cctwo_code"
                      :searchable="false"
                      @input="addcc3()"
                    ></v-select>
                  </div>
                </td>
                <td class="left">
                  <div v-if="!afe.add" class="view" @click="editAfeData(afe, index, aIndex)">
                    <div v-if="afe.ccthree_code"><span v-if="!afe.ccthree_code.includes('---')">
                      {{afe.ccthree_code}}
                      </span>
                    </div>
                  </div>
                  <div v-if="!afe.add" class="edit">
                    <v-select :clearable="false"
                      v-model="edit_ccthree_code"
                      label="ccthree_code"
                      :options="cc3Data"
                      :value="ccthree_code"
                    ></v-select>
                  </div>
                  <div v-if="afe.add == 1">
                    <v-select :clearable="false"
                      v-model="add_ccthree_code"
                      label="ccthree_code"
                      :options="cc3Data"
                      :value="ccthree_code"
                      :searchable="false"
                    ></v-select>
                  </div>
                </td>
                <td class="actions add-afe-button" style="width: 5%;">
                  <div v-if="!afe.add" class="edit">
                    <button type="button" v-on:click="editAddAfeToInvoice(afe.apwid, afe.wsid, afe)" class="btn btn-sm btn-primary"><i class="fa fa-edit"></i></button>
                  </div>
                  <div v-if="afe.add == 1">
                    <button type="button" v-b-tooltip.hover title="Add AFE" v-on:click="addAfeToInvoice(afe.service_ccid, afe.wsid, afe.id)" class="btn btn-sm btn-success"><i class="fa fa-plus-circle"></i></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button v-b-tooltip.hover title="Add AFE" :disabled="beforeEditAfeCacheCatch == 1 || beforeEditCacheCatch == 1" type="button" v-on:click="addNewAfe(service.wsid, service.service[0].serviceid.serviceid, index)" class="view btn rt-d-inline btn-sm btn-success">Add AFE <i class="fa fa-plus-circle"></i></button>
          </b-collapse>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate';
import moment from 'moment';

export default {
  name: 'WSSEstimates',
  props: ['projectid', 'serviceid', 'companyid', 'update'],
  components: {
    vSelect,
    Datepicker,
  },
  watch: {
    fullVendorServices: function (val) {
      if(val.length > 0 && val.length < 2){
        this.extendLine();
      }
      if(val.length == this.vendorServices.length){
        this.loadExisting(this.projectid);
      }
    },
    update: function (newVal) {
      if(newVal) {
        console.log('reloading')
        this.loadData();
      }
    },
  },
  data () {
    return {
      project: [],
      serviceData: [],
      vendorServices: [],
      editedAfeLine: [],
      beforeEditCacheCatch: 0,
      beforeEditAfeCacheCatch: 0,
      preServiceData: [],
      vasid: 0,
      vendorData: [],
      projectData: [],
      vendorServices: [],
      fullVendorServices: [],
      editedLine: [],
      beforeEditCache: [],
      addAfeButtons: 1,
      afe: '',
      serviceChainData: [],
      afenum: '',
      cc1: '',
      cc1Data: [],
      ccone_code: '',
      cc2: '',
      cctwo_code: '',
      cc2Data: [],
      cctwo_code: '',
      cc3: '',
      ccthree_code: '',
      cc3Data: [],
      ccthree_code: '',
      addAfeShow: false,
      percent: 100,
      afes: [],
      edit_afe: [],
      edit_percentage: 0,
      edit_ccone_code: [],
      edit_cctwo_code: [],
      edit_ccthree_code: [],
      add_afe: [],
      add_percentage: 100,
      add_ccone_code: [],
      add_cctwo_code: [],
      add_ccthree_code: [],
      editAfe: 0,

      discounted_price_per_unit:0,
    }
  },
  methods: {


//price per unit to discounted price per unit toggler;

 Pu_toggler(service,discount,pu){
 
if(!discount){

return service.discounted_price_per_unit = pu.toFixed(2);

}else{

 return service.discounted_price_per_unit = pu*(1-discount/100); 

}

},


Discount(service,discountedPrice,pu){


  service.discount = 100*(1-(discountedPrice/pu));  // pu- price per unit

  return service.discount;

  
},

discounted(service,discount,pu){

return service.discounted_price_per_unit = pu*(1-discount/100); // pu- price per unit


},


    async editAddAfeToInvoice(id, wsid, afe){
      if(this.edit_percentage > 100 || this.edit_percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }

      var cc_num = [];
      if(this.edit_ccthree_code !== undefined && this.edit_ccthree_code !== null){
        cc_num = this.edit_ccthree_code.items[0];
      }
      else {
        cc_num = this.edit_cctwo_code.items[0];
      }

      if(this.serviceData.filter(e => e.service_ccid === cc_num.service_ccid).length > 0){
        if(cc_num.service_ccid == this.beforeEditAfeCache.service_ccid){
        }
        else{
          this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
          return;
        }
      }

      let data = JSON.stringify({
        id: afe.apwid,
        projectid: this.project.projectid,
      })

      const firstRequest = await this.$axios.post('/wss/delete/wafp', data)

      let data2 = JSON.stringify({
        id: wsid,
        projectid: this.project.projectid,
        service_ccid: cc_num.service_ccid,
        percentage: this.edit_percentage,
      })

      const secondRequest = await this.$axios.post('/wss/create/wafp', data2)
      .then(response => {
        var find = this.serviceData.findIndex(x=>x.apwid == id)
        var tempService = this.fullVendorServices.filter(x=>x.serviceid === afe.id);

        Vue.set(this.serviceData, find, {
          serviceid: tempService[0],
          wsid: wsid,
          apwid: response.data.apwid,
          ccthree_code: cc_num.ccthree_code,
          cctwo_code: cc_num.cctwo_code,
          ccone_code: cc_num.ccone_code,
          afenum: cc_num.afenum,
          percentage: this.edit_percentage,
          id: afe.id,
        })

        this.addAfeButtons = 1;
        this.editedAfeLine = [];
        this.beforeEditAfeCacheCatch = 0;
      })
      .catch(error => {
        console.log(error)
        this.$toasted.show('Error', {type: 'error', duration: '3000'})
      })
    },
    editAfeData (line, index, AIndex) {
      if(this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save edit first', {type: 'error', duration: '3000'})
        return
      }
      this.loadEditingAfe(line, index, AIndex)

      this.beforeEditAfeCache = Object.assign({}, line);
      this.editedAfeLine = line
      this.beforeEditAfeCacheCatch = JSON.parse(JSON.stringify(1));
    },
    cancelAfe(index){
      var find = this.serviceData.findIndex(x=>x.add == 1)
      Vue.delete(this.serviceData, find)
      this.beforeEditAfeCacheCatch = 0;
      this.beforeEditCacheCatch = 0;
    },
    loadEditingAfe(line, index, AIndex){
      this.editAfe = 1;

      this.$http.get("/wss/get/service_chains/" + this.companyid + "/" + this.projectid + "/" + line.id)
      .then(response => {
        //Group AFES

        var result = this.lodash.groupBy(response.data.result,'afenum')
        this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
        var currentAfe = this.serviceChainData.findIndex(x=>x.afenum == line.afenum)
        this.edit_afe = this.serviceChainData[currentAfe]


        this.edit_percentage = line.percentage;
        this.editAfe = 1;

        //cc1
        var temp = this.edit_afe.items;
        temp = this.lodash.groupBy(temp, 'ccone_code');
        this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
        var cc1Index = this.cc1Data.findIndex(x=>x.ccone_code == line.ccone_code)
        this.edit_ccone_code = this.cc1Data[cc1Index];
        this.editAfe = 1;


        //cc2
        var temp2 = this.edit_ccone_code.items;
        temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
        this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
        var cc2Index = this.cc2Data.findIndex(x=>x.cctwo_code == line.cctwo_code)
        this.edit_cctwo_code = this.cc2Data[cc2Index];
        this.editAfe = 1;

        //cc3
        var temp3 = this.edit_cctwo_code.items.filter(o => !o.ccthree_code.includes('---'));;
        temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
        this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
        var cc3Index = this.cc3Data.findIndex(x=>x.ccthree_code == line.ccthree_code)
        this.edit_ccthree_code = this.cc3Data[cc3Index];
        this.editAfe = 1;

      }).then(response => {
        this.beforeEditAfeCacheCatch = JSON.parse(JSON.stringify(1));
        this.editAfe = 0;
      })
      .catch(error => {
      })
    },
    editData (line, index) {
      if(this.beforeEditCacheCatch == 1 || this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save edit first', {type: 'error', duration: '3000'})
        return
      }

      this.beforeEditCache = Object.assign({}, line);
      this.editedLine = line
      this.beforeEditCacheCatch = JSON.parse(JSON.stringify(1));
    },
    editService(service) {
      if(service.serviceid && service.uom){
        this.loading = true;

        let data = JSON.stringify({
          wsid: service.wsid,
          vasid: service.serviceid.vasid,
          serviceid: service.serviceid.serviceid,
          start_date:  this.$moment(service.start_date).format('YYYY-MM-DD'),
          end_date:  this.$moment(service.end_date).format('YYYY-MM-DD'),
          discounted_price_per_unit: service.discounted_price_per_unit,
          discount:  service.discount,
          projectid: this.project.projectid,
          quantity:  service.quantity,
          uom:  service.uom[1],
          pu: service.uom[0],
          currency:  service.serviceid.currency,
        })

        this.$axios.post('/wss/edit/service', data)
        .then(response => {
          var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))
          var uom = [service.uom[0],service.uom[1]];

          var i = this.serviceData.findIndex(x=>x.wsid == service.wsid)

          Vue.set(this.serviceData, i, {
            wsid: service.wsid,
            vsid: response.data.vsid,
            id: service.serviceid.serviceid,
            serviceid: service.serviceid,
            start_date:  this.$moment(service.start_date).toDate(),
            end_date: this.$moment(service.end_date).toDate(),
            discounted_price_per_unit: service.discounted_price_per_unit,
            discount:  service.discount,
            percentage:  0,
            quantity:  service.quantity,
            uom:  uom,
            pu: service.uom[0],
            total: total,
          });

          this.editedLine = [];
          this.beforeEditCacheCatch = 0;
          this.loading = false;
        })
        .catch(error => {
        })
      }
    },
    addNewAfe (id, serviceid, index) {
      this.$http.get("/wss/get/service_chains/" + this.companyid + "/" + this.projectid + "/" + serviceid)
      .then(response => {

        var result = this.lodash.groupBy(response.data.result,'afenum')
        this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
        this.add_afe = this.serviceChainData[0]
        this.add_percentage = 100;

        //cc1
        var temp = this.add_afe.items;
        temp = this.lodash.groupBy(temp, 'ccone_code');
        this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
        this.add_ccone_code = this.cc1Data[0];

        //cc2
        var temp2 = this.add_ccone_code.items;
        temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
        this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
        this.add_cctwo_code = this.cc2Data[0];

        //cc3
        var temp3 = this.add_cctwo_code.items;
        temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
        this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
        this.add_ccthree_code = this.cc3Data[0];

        this.serviceData.push({
          wsid: id,
          add: 1,
          id:  serviceid,
          afenum: ' '
        })
      }).then(response => {
        this.beforeEditAfeCacheCatch = JSON.parse(JSON.stringify(1));
        this.editAfe = 0;
      })
      .catch(error => {
      })
    },
    addAfeToInvoice(id, wsid, serviceid){
      if(this.add_percentage > 100 || this.add_percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }

      var cc_num = [];
      if(this.add_ccthree_code !== undefined && this.add_ccthree_code !== null){
        cc_num = this.add_ccthree_code.items[0];
      }
      else {
        cc_num = this.add_cctwo_code.items[0];
      }

      if(this.serviceData.filter(e => e.service_ccid === cc_num.service_ccid).length > 0){
        this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
        return;
      }

      let data = JSON.stringify({
        projectid: this.project.projectid,
        id: wsid,
        service_ccid: cc_num.service_ccid,
        percentage: this.add_percentage
      })

      this.$axios.post('/wss/create/wafp', data)
      .then(response => {
        var find = this.serviceData.findIndex(x=>x.add == 1)

        Vue.set(this.serviceData, find, {
          wsid: wsid,
          apwid: response.data.apwid,
          ccthree_code: cc_num.ccthree_code,
          cctwo_code: cc_num.cctwo_code,
          ccone_code: cc_num.ccone_code,
          afenum: cc_num.afenum,
          service_ccid: cc_num.service_ccid,
          percentage: this.add_percentage,
          id: serviceid
        })
        this.beforeEditAfeCacheCatch = 0;
      })
      .catch(error => {
      })
    },
    getcc1() {
      if(this.editAfe == 0){
        var temp = this.edit_afe.items;
        temp = this.lodash.groupBy(temp, 'ccone_code');
        this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
        this.edit_ccone_code = this.cc1Data[0];
      }
    },
    getcc2() {
      if(this.editAfe == 0){
        if(this.edit_ccone_code){
          if(this.edit_ccone_code.items){
            var temp2 = this.edit_ccone_code.items;
            temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
            this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
            this.edit_cctwo_code = this.cc2Data[0];
          }
        }
      }
    },
    getcc3() {
      if(this.editAfe == 0){
        if(this.edit_cctwo_code){
          if(this.edit_cctwo_code.items){
            var temp3 = this.edit_cctwo_code.items.filter(o => !o.ccthree_code.includes('---'));
            temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
            this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
            this.edit_ccthree_code = this.cc3Data[0];
          }
        }
      }
    },
    addcc1() {
      var temp = this.add_afe.items;
      temp = this.lodash.groupBy(temp, 'ccone_code');
      this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
      this.add_ccone_code = this.cc1Data[0];
    },
    addcc2() {
      if(this.add_ccone_code){
        if(this.add_ccone_code.items){
          var temp2 = this.add_ccone_code.items;
          temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
          this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
          this.add_cctwo_code = this.cc2Data[0];
        }
      }
    },
    addcc3() {
      if(this.add_cctwo_code){
        if(this.add_cctwo_code.items){
          var temp3 = this.add_cctwo_code.items.filter(o => !o.ccthree_code.includes('---'));
          temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
          this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
          this.add_ccthree_code = this.cc3Data[0];
        }
      }
    },
    removeService (i, wsid) {
      let data = JSON.stringify({
        id: wsid,
        projectid: this.project.projectid,
      })
      this.$axios.post('/wss/delete/service', data)
      .then(response => {
        Vue.delete(this.serviceData, i);
      })
      .catch(error => {
      })
    },
    removeAfe (apwid) {
      var i = this.serviceData.findIndex(x=>x.apwid == apwid)

      let data = JSON.stringify({
        id: apwid,
        projectid: this.project.projectid,
      })
      this.$axios.post('/wss/delete/wafp', data)
      .then(response => {
        Vue.delete(this.serviceData, i);
      })
      .catch(error => {
      })
    },
    extendLine(){
      this.preServiceData.push(Vue.util.extend({}, {
        start_date: new Date,
        end_date: new Date,
        serviceid: [],
        quantity: 0,
        discount:  0,
        percentage:  0,
        quantity:  0,
        total: 0,
        afe: [],
      }))
    },
    loadData() {
      this.loadVendors(this.projectid);
    },
    loadVendors(pid) {
      if(pid){
        this.$http.get('/wss/get/vendor_services/' + pid)
        .then(response => {
          this.vendorData = this.lodash.uniqBy(response.data.result, 'company_name');
          this.vendorServices = response.data.result;

          for (var i = 0; i < this.vendorServices.length; i++) {
            var id = this.vendorServices[i].vasid;
            var name = this.vendorServices[i].name;
            this.createFullList(id, name, i, pid)
          }
        })
      }
    },
    createFullList(id, name, i, pid){
      this.$http.get('/wss/get/service_details/' + pid + '/' + id)
      .then(response => {
        var temp = response.data.result;

        temp.name = this.vendorServices[i].name;
        temp.vasid = id;
        temp.vid = this.vendorServices[i].vid;
        this.fullVendorServices.push(temp);
      })
    },
    loadExisting(id){
      if(id){
        this.$http.get('/wss/get/full_services/' + id)
        .then(response => {
          var temp = response.data.result;

          for (var i = 0; i < temp.length; i++) {
            var tempService = this.fullVendorServices.filter(x=>x.serviceid === temp[i].serviceid);
            console.log(tempService);

            this.serviceData.push({
              wsid: temp[i].wsid,
              vsid: temp[i].vsid,
              id: temp[i].serviceid,
              serviceid: tempService[0],
              start_date: this.$moment(temp[i].start_date).toDate(),
              end_date: this.$moment(temp[i].end_date).toDate(),
              discount: temp[i].discount,
              quantity: temp[i].quantity,
              uom: [
                temp[i].pu,
                temp[i].uom
              ],
              pu: temp[i].pu,
              total: this.formatPrice((temp[i].pu * temp[i].quantity) - (temp[i].discount/100 * (temp[i].pu * temp[i].quantity))),
              apwid: temp[i].apwid,
              service_ccid: temp[i].service_ccid,
              ccthree_code: temp[i].ccthree_code,
              cctwo_code: temp[i].cctwo_code,
              ccone_code: temp[i].ccone_code,
              afenum: temp[i].afenum,
              percentage: temp[i].percentage,
            })
          }
        })
        this.$http.get('/wss/get/services/' + id)
        .then(response => {
          var temp = response.data.result;
          for (var i = 0; i < temp.length; i++) {
            var tempService = this.fullVendorServices.filter(x=>x.serviceid === temp[i].serviceid);
            this.serviceData.push({
              wsid: temp[i].wsid,
              vsid: temp[i].vsid,
              id: temp[i].serviceid,
              serviceid: tempService[0],
              start_date: this.$moment(temp[i].start_date).toDate(),
              end_date: this.$moment(temp[i].end_date).toDate(),
              discount: temp[i].discount,
              quantity: temp[i].quantity,
              uom: [
                temp[i].pu,
                temp[i].uom
              ],
              pu: temp[i].pu,
              total: this.formatPrice((temp[i].pu * temp[i].quantity) - (temp[i].discount/100 * (temp[i].pu * temp[i].quantity))),
            })
          }
        })
      }
    }
  },
  computed: {
    sortedServiceData: function () {
      return this.lodash.chain(this.serviceData.filter(x=>x.id == this.serviceid))
      .groupBy('wsid')
      .map((service, wsid) => ({ service, wsid }))
      .value();
    },
    filterVendorData: function () {
      //return this.lodash.sortBy(this.serviceData, ["number"])
      return this.fullVendorServices.filter(x=>x.vid === this.vasid.vid)
    },
  },
  mounted(){
    this.project.projectid = this.projectid;
    this.loadData();
  }
}

</script>

<style lang="scss">
.service-rows td,
.service-rows th {
  width: 14%;
  vertical-align: middle;
}

.e-service-rows {
  th, td {
    width: 14%;
  }

  .price,
  .price input {
    width: 55px !important;
  }
  .datepickers input {
    max-width: 110px;
    width: 110px;
  }
}

.edit, .add {
  display: none;
}

.editing .edit, .editing .add  {
  display: block;
}
.editing .edit.rt-d-inline,
.editing .add.rt-d-inline
{
  display: inline;
}
.editing .view {
  display: none;
}

.edit {
  display: none;
}
.editing .afe {
  display: block;
}
.editing .afe.rt-d-inline
{
  display: inline;
}
.editing .view {
  display: none;
}

.editAfe {
  display: none;
}
.editingAfe .edit {
  display: block;
}
.editingAfe .edit.rt-d-inline
{
  display: inline;
}
.editingAfe .view {
  display: none;
}

.pre-table th{
  border-top: none;
}

@media screen and (max-width: 1200px) {

}
@media screen and (min-width: 1200px) {

}
</style>
